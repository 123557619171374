import "./RevMeter.css";

const RevMeter = () => {
  return (
    <div className="rev-meter-section">
      <div className="rev-meter-parent">
        <div className="rev-meter-child">

        </div>
        <div className="rev-meter-pointer">

        </div>
      </div>
    </div>
  );
};

export default RevMeter;
